body {
  user-select: none; /* Standard syntax */
  -webkit-user-select: none; /* Chrome, Safari, Opera */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}
main {
  height: 100vh;
}
#main-nav,
.navbar {
  z-index: 9;
}
#board {
  background: url("./images/felt.png"), rgb(16, 110, 16);
  min-height: 100px;
}
#actions {
  background: url("./images/metal.png"), #212529;
}
#opponent {
  background: url("./images/metal.png"), #212529;
}
#waiting {
  width: 90vw;
  max-width: 600px;
}
.playing-card {
  border: 2px solid grey;
  border-radius: 5px;
  margin-left: -15px;
  min-width: 48px;
  max-width: 115px;
  width: 7.5vw;
}
.board-card {
  border: 1px solid grey;
  border-radius: 5px;
  min-width: 60px;
  max-width: 115px;
  width: 9vw;
  margin: 20px 1px;
  box-shadow: 3px 3px 6px 0px #00000029;
}
.cards-played{
  position: absolute;
  opacity: 0.5;
  top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.opponent-card:first-of-type {
  margin-left: 0;
}
.opponent-card {
  --size: 10vw;
  --max-size: 150px;
  height: calc(var(--size) * 1);
  width: calc(var(--size) * 0.75);
  max-height: calc(var(--max-size) * 1);
  max-width: calc(var(--max-size) * 0.75);

  margin-left: calc(var(--size) * -0.3);
  border: 3px solid white;
  box-shadow: inset 0px 0px 0px 10px #7d7cad;
  background: #9291ad;
  border-radius: 6px;
}
.minor-buttons {
  width: 150px;
}
.selected {
  margin-top: -50px;
  border: 3px solid green;
}
.waiting {
  width: 100%;
  text-align: right;
}
img {
  user-select: none;
}

.emojis {
  position: relative;
  display: flex;
  justify-content: center;
  transition: all 2s;
}
.emojis .active {
  animation: slide-out-top 0.5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;
}

#emoji {
  width: auto !important;
}
.emojis h3 {
  cursor: pointer;
  font-size: 2rem;
  margin: -25px 5px 10px 5px;
  z-index: 10;
}
.opponent-speech {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 10px;
}
#passwordStrength {
  width: 100%;
  height: 10px;
  border-radius: 5px;
}

@keyframes slide-out-top {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-300px);
    opacity: 0;
  }
}
